import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "2020 年度新卒エンジニア研修について",
  "date": "2020-09-25T08:45:27.000Z",
  "slug": "entry/2020/09/25/174527",
  "tags": ["medley"],
  "hero": "./2020_09_25.png",
  "heroAlt": "2020 新卒研修"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは。`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`の開発チームでエンジニアをしている新居です。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`2020 年 4 月に、新卒エンジニア 3 名が入社しました。
入社後は新卒エンジニア研修を実施し、先日 8 月 25 日の最終報告会をもって終了しました。`}</p>
    <p>{`コロナウイルスの影響で入社間もなくフルリモート勤務となり、不慣れなところもありましたが、本年度の研修の取り組みを紹介します。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200924/20200924130505.png",
      "alt": "20200924130505.png"
    }}></img>
    <p><em parentName="p">{`2020 年度新卒エンジニア`}</em></p>
    <h1>{`研修の概要`}</h1>
    <p>{`メドレーでは昨年度から新卒エンジニアを迎えており、合わせて研修も開始しました。`}</p>
    <p>{`初めての研修をどのような視点で計画・実施したかについては、昨年平木がこちらにまとめています。`}</p>
    <p><a parentName="p" {...{
        "href": "/entry/2019/12/16/165947"
      }}>{`2019 年度エンジニア新卒の研修について - Medley Developer Blog`}</a></p>
    <p>{`今年は人事部のご協力もいただきながら、昨年の内容を少しアップデートして行いました。`}</p>
    <p>{`研修の目的は、`}<strong parentName="p">{`新卒メンバーが同じ空間で互いに刺激し合いながら、社会人への思考転換をはかり、業務遂行に必要となる基礎知識とスキルを習得すること`}</strong>{`です。`}</p>
    <p>{`研修は以下の 4 つのフェーズに区切って行いました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200924/20200924130533.png",
      "alt": "20200924130533.png"
    }}></img>
    <p><em parentName="p">{`研修のフェーズ`}</em></p>
    <h1>{`研修の内容`}</h1>
    <p>{`ここから 4 つのフェーズ毎に内容を紹介します。`}</p>
    <h2>{`フェーズ 1：社会人&メドレー基礎研修`}</h2>
    <p><strong parentName="p">{`1. オリエンテーション`}</strong></p>
    <ul>
      <li parentName="ul">{`メドレーの事業や組織、大切にしている行動規範などの概要説明`}</li>
      <li parentName="ul">{`セキュリティ研修とコンプライアンス研修`}</li>
    </ul>
    <p><strong parentName="p">{`2. ビジネス研修`}</strong></p>
    <ul>
      <li parentName="ul">{`ビジネスマナー研修`}</li>
      <li parentName="ul">{`ビジネススキル研修`}</li>
      <li parentName="ul">{`ビジネススタンス研修（外部研修）`}</li>
    </ul>
    <p>{`フェーズ 1 では「医療ヘルスケアの未来をつくる」メンバーの一員として大切にして欲しいことを学ぶフェーズでした。`}</p>
    <p>{`社会人としての最低限のマナーやスタンスは勿論ですが、メドレーで働く上で土台となるマインドをここで学び、`}<strong parentName="p">{`医療ヘルスケア分野の課題を解決する一員`}</strong>{`として共にプロダクトを作るためのベースを築けたと思います。`}</p>
    <h2>{`フェーズ 2：エンジニア基礎研修`}</h2>
    <p><strong parentName="p">{`1. 開発基礎 1`}</strong></p>
    <ul>
      <li parentName="ul">{`講義「メドレーが求めるエンジニアとは」`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://job-medley.com/"
        }}>{`ジョブメドレー`}</a>{`と`}<a parentName="li" {...{
          "href": "https://clinics.medley.life/"
        }}>{`CLINICS`}</a>{`の事業・プロダクトについての概要説明`}</li>
      <li parentName="ul">{`Ruby on Rails（以下 Rails）の基礎トレーニング`}</li>
    </ul>
    <p><strong parentName="p">{`2. 開発実践`}</strong></p>
    <ul>
      <li parentName="ul">{`チーム開発体験`}</li>
    </ul>
    <p><strong parentName="p">{`3. 開発基礎 2`}</strong></p>
    <ul>
      <li parentName="ul">{`書籍`}<a parentName="li" {...{
          "href": "https://www.amazon.co.jp/dp/4774142042/"
        }}>{`『Web を支える技術 -HTTP、URI、HTML、そして REST』`}</a>{`の輪読会`}</li>
      <li parentName="ul">{`ドキュメンテーション研修とプレゼンテーション研修`}</li>
      <li parentName="ul">{`中間報告会の準備と実施`}</li>
    </ul>
    <p>{`フェーズ 2 から開発の研修がスタートしました。`}</p>
    <h3>{`開発基礎 1`}</h3>
    <p>{`開発の研修に入る前に、エンジニアの執行役員 田中から「メドレーが求めるエンジニアとは」というテーマで講義を行いました。メドレーがどういうエンジニアを求めているのか、目指すところの視点合わせを行い、これから行う研修に対する意識や取り組みの質を上げることを目指しました。`}</p>
    <p>{`メドレーが求めるエンジニア像については CTO 平山の記事にも詳しく書いています。`}</p>
    <p><a parentName="p" {...{
        "href": "https://toppa.medley.jp/05.html"
      }}>{`メドレー平山の中央突破: THE エンジニア`}</a></p>
    <p>{`その後ジョブメドレーと CLINICS の事業・プロダクトについての概要説明、Rails の基礎トレーニングを行いました。メドレーのプロダクトは Rails 製です。Rails やウェブアプリケーション開発に慣れていない新卒メンバーは、Rails の基礎トレーニングで`}<a parentName="p" {...{
        "href": "https://railstutorial.jp/"
      }}>{`Ruby on Rails チュートリアル`}</a>{`を使って基礎からみっちり学びます。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200924/20200924130918.png",
      "alt": "20200924130918.png"
    }}></img>
    <p><em parentName="p">{`Ruby on Rails チュートリアルの進め方`}</em></p>
    <p>{`ここで大切なことは、漠然とひたすら写経してチュートリアルを進めるのではなく、毎日のフィードバック会でしっかりその日の進捗や学びを共有し、不明点はメンターに質問してもらうようにすることです。`}</p>
    <p>{`メンターは、新卒メンバーが理解が浅いまま進めてたり、理解していて欲しいところがいい加減になってたり、進め方や学びの方向性がズレてる場合などはアドバイスを入れて軌道修正することを心掛けました。`}</p>
    <p>{`また「実際の開発ではこうだよ」といった実務を踏まえたアドバイスや、意識していることも伝えていきました。`}</p>
    <p><em parentName="p">{`20 新卒 S さんの感想`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`「毎日のフィードバック会の中で、その日学んだ技術がジョブメドレーではどのように利用されているのか、どういったことに留意して利用しているのかなどを確認できたので、現場の人の感覚を少しずつ知ることができた。」`}</p>
    </blockquote>
    <p>{`新卒メンバーは毎日リズム良く進捗を出し、メンターは新卒メンバーのフォローと引き上げを意識し、成果を最大化できるよう努めました。`}</p>
    <h3>{`開発実践`}</h3>
    <p>{`続いて開発実践ではチーム開発を行いました。前回までは各自個別に進めていましたが、ここではジョブメドレーに関する課題解決を目的としたプロジェクトに対して、チームで向き合う研修でした。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200924/20200924131002.png",
      "alt": "20200924131002.png"
    }}></img>
    <p><em parentName="p">{`開発実践の目的と達成すべきこと`}</em></p>
    <p>{`実務ではチーム開発が基本となり、チームメンバーとの協働は必須です。学生時代に業務レベルのチーム開発を経験しているのは稀ですし、実務に入る前にチームでプロジェクト推進するとはどういうことかを知るのは、とても価値があると思います。`}</p>
    <p>{`加えて、チームで課題をどう解決していくのかも、新卒メンバー同士で話し合って決める必要があるので、課題解決力も養われたと思います。`}</p>
    <p>{`今回はプロトタイプを作って成果発表するところまででしたが、プロジェクト推進においてはスケジューリング、要件定義、各種設計、開発フローやコミュニケーションフローの整備、実装、テスト、などなど、やることは尽きません。`}</p>
    <p>{`また、このプロセスの中で密なコミュニケーションが必要不可欠となるので、新卒メンバー間のチームワークも向上し、お互いのことを更に知ることができたと思います。`}</p>
    <p>{`この段階で、チームでプロジェクトを推進することの全体感を知り、プロジェクト推進の苦悩苦闘を実体験できたのは大きな経験値になったと思います。`}</p>
    <p><em parentName="p">{`20 新卒 O さんの感想`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`「各機能の影響を互いに受けないためにブランチを細分化するブランチ戦略や GitHub を用いたコード管理について理解できた。一方で、UI を考える際にチームの各メンバーの認識のズレが生じたことや、序盤は各メンバーの進捗の詳細を把握できていなかったことから報・連・相の重要性を再認識した。」`}</p>
    </blockquote>
    <p><em parentName="p">{`20 新卒 T さんの感想`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`「初めてのチーム開発であったことから、実装の序盤は、どのファイルなら編集しても他のメンバーの作業に影響がないのか、動作確認のための画面を作るファイルは自由に作成して良いのか、どのテーブルから関係する他のテーブルの情報を含めた情報を取得をするかなどに悩んでいた。今になって振り返るとチーム内で話せば解決することに対して、技術的にも心理的にも難しさを感じた。」`}</p>
    </blockquote>
    <p>{`###開発基礎 2`}</p>
    <p>{`フェーズ 2 最後の開発基礎 2 では、書籍`}<a parentName="p" {...{
        "href": "https://www.amazon.co.jp/dp/4774142042/"
      }}>{`『Web を支える技術 -HTTP、URI、HTML、そして REST』`}</a>{`の輪読会を行いました。もっと前のフェーズでの実施も検討しましたが、開発基礎 1 と開発実践を経た後の方が書籍の内容の納得感が高くなるだろうという判断で、この段階での実施となりました。`}</p>
    <p>{`その後、中間報告会に向けたドキュメンテーション研修とプレゼンテーション研修を行いました。仕事を進めていく上では、背景や目的を正しくステークホルダーへ共有しながら進めていくことが必要で、伝えたいことを適切に文章として整理し、他者へ分かりやすく伝えていくことが求められます。中間報告会では、そういったことを意識しながらこれまでの研修でやってきたことや成果、学びをレポートにまとめ、プロダクト開発室の室長と副室長、メンター陣の前でプレゼンテーションして報告しました。`}</p>
    <h2>{`フェーズ 3：事業部 OJT`}</h2>
    <p><strong parentName="p">{`1.代表取締役医師 豊田の講義`}</strong></p>
    <ul>
      <li parentName="ul">{`日本における医療制度の課題とそれに対するメドレーの位置付けについての講義`}</li>
    </ul>
    <p><strong parentName="p">{`2.ジョブメドレー開発 OJT`}</strong></p>
    <ul>
      <li parentName="ul">{`ジョブメドレーの実際の開発 Issue に対応し、ジョブメドレーの開発プロセスを体験`}</li>
    </ul>
    <p>{`フェーズ 3 の最初は豊田代表の講義を受けました。基礎的な研修を終え、ジョブメドレー開発 OJT に入る前にメドレー社の社会的意義などを改めて代表から伝えていただきました。`}</p>
    <p>{`続いて、ジョブメドレー開発 OJT は以下の狙いを持って進めました。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200924/20200924131055.png",
      "alt": "20200924131055.png"
    }}></img>
    <p><em parentName="p">{`ジョブメドレー開発 OJT の狙い`}</em></p>
    <p>{`実際に行ったことは、手始めに小さい不具合系 Issue の対応に取り組んだ後、サーバーレスポンス改善の Issue に取り組むというものでした。`}</p>
    <p>{`サーバーレスポンスの改善は、1 人 1 画面を担当し、「プロファイルツールで分析 → 改善できそうな箇所を調査 → 改善方針の検討 → 提案 → 実装 → レビュー → リリース」というサイクルを回しました。`}</p>
    <p>{`1 つ目の Issue 対応でも心掛けたことですが、言われたものを実装するのではなく、`}<strong parentName="p">{`ある課題を解決するためにどうしたら良いかを自分で主体的に考えることを重視しました。実装力と同じくらい課題解決力も大切にしているためです。`}</strong></p>
    <p>{`とはいえ成果が何も出せないというのは、精神衛生上良くないので、日々の朝会と夕会にて進捗や状況をしっかり確認しながら適宜フォローやインプットを行い、ヒントになりそうな過去 Issue のリンクを渡して参考にしてもらったり、メンターの適切なバックアップも必要です。`}</p>
    <p>{`今回の OJT を通じて実務を知ることで、実際にエンジニアとして仕事をするイメージが沸き、同時に仕事をする上で足りないことも明確になります。自分の課題と向き合うことで、直近の自身の学習プランの軌道修正もできます。`}</p>
    <p>{`課題は簡単なものではありませんでしたが、最終的に 1 人 1 つ以上のサーバーレスポンス改善 PR をリリースすることができました。`}</p>
    <p><em parentName="p">{`S さんの感想`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`「速度パフォーマンスの悪いコードに対する嗅覚、オブジェクトを生成しすぎていないか、無駄に通信を走らせていないかなどを学べた。」`}</p>
    </blockquote>
    <p><em parentName="p">{`O さんの感想`}</em></p>
    <blockquote>
      <p parentName="blockquote">{`「Issue を本質的に解決するメドレーの開発姿勢について学んだ。Issue を表面的に解決するのではなく、背景や目的、関連するコードなどの不明点を調査し、十分に理解・納得した上で修正することの意識付けができた。また、Issue に関連する一部分のコードを修正すれば良いという狭い視野を持たず、修正による影響範囲を考慮した上で全体の最適化を考えることが重要であると学んだ。」`}</p>
    </blockquote>
    <h2>{`フェーズ 4：最終報告`}</h2>
    <p><strong parentName="p">{`1. 最終報告会`}</strong></p>
    <ul>
      <li parentName="ul">{`最終報告会の準備と実施`}</li>
    </ul>
    <p>{`最後のフェーズ 4 では、これまでの研修でやってきたことや成果、学びをレポートにまとめ、役員陣の前でプレゼンテーションして報告しました。`}</p>
    <p>{`役員陣の前なので緊張は最高潮になりますが、自分達が研修を通じて何を得てどう成長したか、今後どういうエンジニアを目指していくのか、などを役員陣の前でアピールする貴重な機会となりました。`}</p>
    <p>{`1 人 10 分の枠内で役員陣にどういう情報をどういう表現でプレゼンテーションするかを考える機会にもなったので、情報整理力や表現力が培われる場にもなりました。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`2020 年度の新卒エンジニア研修も無事終了することができました。改めて、新卒メンバーのみなさん、関係者のみなさん本当にお疲れ様でした。`}</p>
    <p>{`振り返ると、メドレー社員としてのマインドや開発の基本的なことをしっかりインプットしつつ、新卒メンバーが主体的に課題解決に取り組む研修ができたように思います。`}</p>
    <p>{`必要な技術スタックを 1 から 10 まで懇切丁寧に資料に落とし込み、講義形式で行う研修も身になることは多いですが、`}<strong parentName="p">{`メドレーの研修のような課題解決を実体験する研修はより実務に繋がる実践的な研修だと思います。`}</strong></p>
    <p>{`少しハードな面もあるかもしれませんが、このような研修を乗り越え、医療ヘルスケア分野の課題解決に取り組みたい学生エンジニアのみなさん、少しでも興味を持っていただけると幸いです。`}</p>
    <p>{`もちろん中途エンジニアの方もぜひお気軽にお話をしましょう。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      